<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Impressionen</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div v-if="loading">
            <div class="loading-wrap">
              <div class="loading-spinner"></div>
            </div>
          </div>
          <div v-else>
            <p style="text-align: center; font-size: 16px">
              <strong>Impressionen Gesamt: {{ viewer_count }}</strong>
            </p>

            <table class="table">
              <thead>
                <tr>
                  <th>Device ID</th>
                  <th>{{ $t("global.name") }}</th>
                  <th>Hersteller</th>
                  <th>Einträge</th>
                  <th>Signal Entfernung</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="viewer in viewers" v-bind:key="viewer.uuid">
                  <td width="20%">
                    <router-link :to="'/admin/viewers/' + viewer.uuid" style="color: #444; font-weight: 600">{{ viewer.device_id }}</router-link>
                  </td>
                  <td width="20%">{{ viewer.name }}</td>
                  <td width="20%">
                    <span v-if="viewer.manufacturer">{{ viewer.manufacturer.name }}</span>
                  </td>
                  <td width="20%">{{ viewer.times_seen }}x gesehen</td>
                  <td width="20%">{{ 10 ** ((-69 -( viewer.rssi))/(10 * 2)) }} Meter</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "admin_viewers",
  components: {
    AdminNav,
  },
  data() {
    return {
      viewers: [],
      loading: true,
      viewer_count: 0, 
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/viewers", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.viewers = response.data.viewers;
          this.viewer_count = response.data.meta.pagination.total_objects;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
